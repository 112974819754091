<template>
  <div>
    <h5>{{$t('creator.decline_pitch')}}</h5>
    <p>{{$t('creator.decline_pitch_suggest')}}</p>
    <validation-observer ref="updateProfile" #default="{ invalid }">
      <validation-provider rules="required">
          <b-form-group :label="$t('creator.reason')" label-for="input-reason" class="form-group-layout">
            <v-select
              v-model="reject_reason"
              :options="$t('creator.table_casting_calls.options_reasons')"
              class="mb-1"
            >
            </v-select>
          </b-form-group>
        </validation-provider>
        <validation-provider rules="required">
          <b-form-group :label="$t('creator.message_seller_declined')" label-for="input-message" class="form-group-layout">
            <b-form-textarea v-model="reject_message" rows="1" max-rows="6"></b-form-textarea>
          </b-form-group>
        </validation-provider>
        <div class="d-flex justify-content-end mb-1">
          <b-button variant="light" @click="$emit('hidden')">{{$t('search.cancel')}}</b-button>
          <b-button variant="blue-button" :disabled="invalid" class="blue-button ml-1" @click="rejectPitches()">{{$t('creator.submit')}}</b-button>
        </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormTextarea,
  BButton
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from 'vue-select';
import pitch_service from '@/services/pitch_service';

export default {
  name: 'bulkApproveReject',
  props: {
    checked_pitches: {
      type: Array,
    },
    reject_all: {
      type: Boolean,
      default: false
    },
    casting_call_uuid: {
      type: String,
      default: ''
    }
  },
  components: {
    BFormGroup,
    BFormTextarea,
    BButton,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      reject_reason: '',
      reject_message: ''
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    rejectPitches() {
      if (!this.reject_all) {
        const data = {
          'pitches_uuid': this.checked_pitches,
          'status': 'rejected',
          'reject_reason': this.reject_reason.value,
          'reject_message': this.reject_message,
        }

        pitch_service.bulkacceptRejectPitch(data).then(() => {
          this.$emit('pitchesRejected')
        })
      } else {
        const data = {
          'reject_reason': this.reject_reason.value,
          'reject_message': this.reject_message,
        }

        pitch_service.rejectSubmittedPitches(this.casting_call_uuid, data).then(response => {
          this.$emit('allPitchesRejected', response.data.response.pitches_uuid)
        })
      }
    }
  },
  watch: {
  }
}
</script>
